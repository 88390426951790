.solution-text-bold {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 23px;
	text-align: center;
	margin: auto;
	@media screen and (max-width: 1024px) {
		font-size: 14px;
		line-height: 17px;
		max-width: 100%;
	}
}

.solution-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 23px;
	max-width: 620px;
	text-align: center;
	margin: auto;
	margin-bottom: 30px;
	@media screen and (max-width: 1024px) {
		font-size: 14px;
		line-height: 17px;
		margin-bottom: 15px;
		max-width: 100%;
	}
}

.solution-col-full {
	flex-direction: row !important;
	width: 100%;
	margin: 0 auto;
	text-align: left;
	margin: auto;
	min-height: 90px;
	height: 100%;
	vertical-align: middle;
}

.solution-instruction-col {
	max-width: 600px;
	flex-direction: row;
	width: 100%;
	@media screen and (max-width: 1024px) {
		max-width: 98vw;
	}
}

.solution-instruction-tr {
	border: none !important;
}

.solution-instruction-td {
	border: none !important;
	padding: 0 !important;
}

.solution-instruction-td-green {
	margin: auto;
	width: 65px;
	@media screen and (max-width: 1024px) {
		width: 45px;
	}
}

.solution-instruction-green-box {
	background-image: url("../../../../assets/images/greenbox.svg") !important;
  background-position: center;
  background-repeat: no-repeat;
	margin: auto;
	width: 50px;
	height: 50px;
	text-align: center;
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 50px;
	margin-top: 25%;
	@media screen and (max-width: 1024px) {
		width: 36px;
		height: 36px;
		font-size: 12px;
		line-height: 36px;
	}
}

.solution-instruction-header {
		font-family: Quantico;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		line-height: 30px;	
		text-transform: uppercase;
		width: 98%;
		@media (max-width: 1024px) {
			font-size: 18px;
			line-height: 22px;
		}
}

.solution-link {
		font-family: Quantico;
		font-style: bold;
		font-size: 18px;
		line-height: 21px;		
		color: #00B448;
		text-decoration: underline;
		@media (max-width: 1024px) {
			font-size: 12px;
			line-height: 16px;
		}
}

.solution-instruction-desc {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 19px;
		margin-bottom: 15px;
		margin-top: 5px;
		@media (max-width: 1024px) {
			font-size: 12px;
			line-height: 16px;
			margin-bottom: 10px;
			margin-top: 5px;
		}
}

.solutionbutton-margin {
	margin-top: 10px;
}

.solution-row-mode {
	flex-direction: column;
	margin: auto;
}