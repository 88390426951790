.container-manifesto-bg {
  width:100vw;
  height:100%;
}
.container-manifesto {
  font-family: "Clarkson";
  background-color: #f9f9f9 !important;
  color: black;
  width: 100% !important;
  max-width: 1500px;
  min-height: 550px;
  padding: 0 !important;
  margin: 0 auto;
  padding-left:40px !important;
}

.manifesto-markdown-container {
  h2 {
    position: relative !important;
    min-height: 96px;
    font-family: 'HelveticaNeueLtExt';
    font-size: 50px;
    font-weight: bold;
    letter-spacing: normal;
    color: #121212;
    width: 95%;
    margin-top: 12px !important;
    margin-bottom: 12px !important;
    padding-right: 12px !important;
     @media screen and (max-width: 1024px) {
      font-size: 36px;
      margin-bottom: 15px;
    }
  }
  p {
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-stretch: condensed;
    color: #121212;
    text-align: left;
    width: 100%;
    padding: 0;
    @media screen and (min-width: 1025px) {
      font-size: 18px;
      margin-bottom: 12px;
    }
  }
  li {
    font-family: "HelveticaNeue" !important;
    font-size: 14px;
    color: #121212;
    padding-bottom: 12px;
    @media screen and (min-width: 1025px) {
      font-size: 16px;
    }
  }
  ol {
    padding: 0 13px !important;
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-stretch: condensed;
  }
}
