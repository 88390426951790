.container-footer-bg {
  background-color: #f9f9f9;
  height: 300px;
  width: 100vw;
  @media (max-width: 1023px) {
    height: 450px;
  }
}
.container-footer {
  font-family: "HelveticaNeueLtExt";
  background-color: #f9f9f9;
  font-size: 16px;
  letter-spacing: 0.86px;
  font-weight: 500;
  line-height: 100%;;
  font-stretch: condensed;
  padding: 0;
  margin: 0 auto;
  min-height: 196px;
  height: 300px;
  bottom: 0;
  width: 100%;
  max-width: 1500px;
  color: #121519;
}

.nav-brand {
  background-image: url("../../../assets/images/ddp-logo-black.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  /* margin-top: 27px; */
  height: 54px;
  width: 140px;
  margin-top: 83px;
  @media (max-width: 1023px) {
    margin-top: 24px;
    width: 123px;
    height: 47px;
    margin-left: 16px;
  }
}

.copyright {
  width: 294px;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 140%;
  /* or 15px */
  text-transform: uppercase;
  margin-top: 27px;
  @media (max-width: 1023px) {
    font-size: 11px;
    width: 294px;
    height: 30px;
    // margin-top: 138px;
    margin-left: 16px;
  }
}

.row-footer {
  padding-left: 0px;
  padding-top: 0px;
  margin: 0;
  margin-right: 0px !important;
  text-align: left;
  margin-bottom: 16px;
  display: flex !important;
  /* padding-top: 34px; */
  @media (max-width: 1023px) {
    display: flex !important;
  }
}

.social-mobile {
  @media (max-width: 1023px) {
    width: 120px;
  }
}
.row {
  @media (max-width: 1023px) {
    display: flex !important;
  }
}

ul {
  padding-left: 0px !important ;
  @media (max-width: 1023px) {
    margin-left: 0 !important;
  }
}

a {
  padding-left: 0px !important;
}

.footer-col {
  position: relative;
  width: 170px;
  padding: 0;
  margin: 0;
  padding-top: 34px;
  text-align: center;
  margin-right: 24px;
  padding-left: 0px !important;
  @media (max-width: 1023px) {
    // position:absolute;
    padding-top: 17px;
    display: inline-block;
  }
}

.footer-col::before {
  content: "";
  width: 170px;
  height: 2px;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  @media (max-width: 1023px) {
    visibility: hidden;
    width: 0px;
    height: 0;
  }
}

.a-footer {
  font-family:'HelveticaCondensed';
  font-size: 15px;
  color: #121519;
  line-height: 140%;
}

.a-footer:hover {
  font-size: 15px;
  color: #55bc7a !important;
}

.facebook-footer {
  width: 24px;
  height: 24px;
  background-image: url("../../../assets/images/facebook-f.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.instagram-footer {
  width: 24px;
  height: 24px;
  margin-left: 24px;
  background-image: url("../../../assets/images/instagram.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.twitter-footer {
  width: 24px;
  height: 24px;
  margin-left: 24px;
  background-image: url("../../../assets/images/twitter-black.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.footer-content {
  margin: 0 auto;
  margin-top: 72px;
  padding-left: 80px;
  @media (max-width: 1023px) {
    margin-top: 32px;
    padding-left: 16px;
  }
}

.footer-col-mobile-wrapper {
  display: flex !important;
  // @media (max-width: 1023px) {
  //   display:inline-block !important;
  // }
}
