.row {
	@media (max-width: 1023px) {
		display: inline-block;
	}
}
.container-thankyou-bg {
	width: 100vw;
	height: 80vh;
	background: linear-gradient(90deg, #ffffff 50%, #121519 50%);
	@media (max-width: 1023px) {
		background: linear-gradient(#ffffff 50%, #121519 50%);
		height: 748px;
	}
}

.container-thankyou {
	width: 100%;
	height: 100%;
	max-width: 1500px !important;
	margin: 0 auto;
}

.thankyou-container-text {
	height: 242px;
	width: 403px;
	margin-left: 60px;
	margin-top: 20%;
	@media (max-width: 1023px) {
		margin-top: 155px;
		display: inline-block;
		margin-left: 16px;
		width: 343px;
	}
}

.thankyou-header {
	position: relative;
	font-family: "HelveticaNeueLtExt";
	font-style: normal;
	font-weight: 500;
	font-size: 42px;
	line-height: 105%;

	/* or 44px */

	/* Grey 900 */
	color: #121519;
	padding-bottom: 16px;
	@media (max-width: 1023px) {
		// margin-top:40px;
		font-size: 36px;
		padding-bottom: 0;
	}
}

.thankyou-header::after {
	position: absolute;
	width: 57px;
	height: 2px;
	background-color: #e5e5e5;
	content: "";
	bottom: 0;
	left: 0;
	@media (max-width: 1023px) {
		visibility: hidden;
		width: 0;
		height: 0;
	}
}

.thankyou-text {
	font-family: "HelveticaNeue";
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 140%;

	/* or 22px */

	/* Grey 900 */
	color: #121519;
	width: 403px;
	height: 66px;
	margin-top: 24px;
	@media (max-width: 1023px) {
		margin-top:8px;
		width:343px;
	}
}

.thankyou-seemore {
	width: 125px;
	position: relative;
	margin-top: 27px;
	font-family: "HelveticaNeue";
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 140%;

	/* identical to box height, or 21px */
	display: flex;
	align-items: flex-end;
	text-transform: uppercase;

	color: #000000;
	padding-right: 8px;
	@media (max-width: 1023px) {
		visibility: hidden;
		width: 0;
		height: 0;
	}
}

.thankyou-seemore::after {
	position: absolute;
	content: "";
	width: 18px;
	height: 18px;
	background-image: url("../../assets/images/right-arrow.png");
	background-repeat: no-repeat;
	background-size: 100%;
	right: 0;
}

.thankyou-globe {
	width: 385px;
	height: 385px;
	background-image: url("../../assets/images/thankyouglobe.svg");
	margin: 0 auto;
	margin-top: 200px;
	position: relative;
	@media (max-width: 1023px) {
		width: 242px;
		height: 242px;
		margin: 0 auto;
		background-size: 100%;
		margin-top: 64px;
		// display: inline-block;
	}
}
