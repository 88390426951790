.terms-modal-body {
	font-family: 'Roboto Condensed';
	background-color: #f9f9f9 !important;
	color: black;
  font-size: 15px;
}

.terms-modal-header {
	font-family: 'Quantico';
	background-color: white !important;
	color: black;
  font-size: 18px;
  font-weight: bold;
}

.form-group-modal {
  margin-top: 10px;
  margin-left: 2%;
}

.form-group-modal-center {
  margin: auto;
	align-content: center;
	text-align: center;
}


.continue-button-modal {
  background-color: #00ff66 !important;
  font-family: "Roboto Condensed" !important;
  font-size: 16px !important;
  font-weight: bold !important;
  font-stretch: condensed !important;
  letter-spacing: 1.6px !important;
  color: #121212 !important;
  border: 0 !important;
  border-radius: 0 !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06) !important;
  margin-top: 15px;
  height: 50px;
  width: 170px;
}

.continue-button-modal:hover {
  background-color: #00e35b !important;
}

.container-userdetails-bg {
  width:100vw;
  height:100%;
  background-color:#f9f9f9;
  overflow: hidden;
}

.container-userdetails {
  width:100%;
  max-width: 1500px;
  height: 100%;
  margin: 0 auto;
}