.button {
  cursor: pointer !important;
  border-color: transparent;
  border-radius: 1px;
  font-weight: bold;
}

a {
  color: #121519;
}

a:hover {
  color: #121519;
  text-decoration: none;
  opacity: 0.8;
}

.container-home {
  width: 100vw;
}
.container-landing-background {
  width: 100vw;
  height: 106vh !important;
  min-height: 551px;
  background-image: url("../../assets/images/Rectangle_53.png") !important;
  background-position: right;
  background-repeat: no-repeat;
  background-size: 56% 100%;
  /* z-index: 1; */
  @media (max-width: 1023px) {
    background-size: 100% 50%;
    background-position: bottom;
    min-height: 942px;
  }
}
.container-landing {
  position: relative;
  background-color: transparent;
  color: black;
  width: 100%;
  max-width: 1500px !important;
  height: 100% !important;
  min-height: 551px;
  /* z-index:-1; */
}

.card-landing {
  position: absolute !important;
  display: inline-block;
  top: 49.73% !important;
  height: 28.98%;
  margin-left: 40px !important;
  @media (max-width: 1023px) {
    margin: 0 auto;
    top: 20% !important;
    // margin-top: 10%;
    // margin-left: 24px !important;
  }
}

.card-landing-header {
  position: relative !important;
  height: 92px;
  font-family: "HelveticaNeueLtExt";
  font-style: normal;
  font-weight: 500;
  font-size: 44px;
  line-height: 105%;
  color: #121519;
}

.card-landing-subheader {
  position: relative !important;
  top: 24px;
  height: 10.23%;
  font-family: "HelveticaNeue";
  font-weight: medium;
  font-size: 18px;
  line-height: 120%;
  color: #333333;
}

.button-landing {
  position: relative;
  top: 60px;
  width: auto;
  height: auto;
  background: #60d78a;
  border-color: #60d78a;

  /* Elevation 3 */
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1), 0px 7px 6px rgba(0, 0, 0, 0.04);
  border-radius: 1px;
  font-family: "HelveticaNeue";
  font-style: normal;
  // font-weight: 500;
  font-size: 15px;
  line-height: 140%;

  // /* identical to box height, or 21px */
  // display: flex;
  // align-items: flex-end;
  // text-align: center;
  // text-transform: uppercase;

  /* Grey 900 */
  color: #121519;
  padding: 12px 40px !important;
}

.button-landing:hover {
  background: #55bc7a !important;
  /* Elevation 3 */
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1), 0px 7px 6px rgba(0, 0, 0, 0.04);
  border-radius: 1px;
  border-color: #55bc7a;
  color: #121519;
}

.container-news {
  /* position: relative; */
  height: 25vh;
  width: 100vw;
  background: #60d78a;
  @media (max-width: 1023px) {
    visibility: hidden;
    height: 0px;
    width: 0px;
  }
}

.news-background {
  position: relative;
  width: 100%;
  max-width: 1500px;
  background-image: url("../../assets/images/News.svg") !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 0;
  height: 100%;
  margin: 0 auto;
}

.container-data-bg {
  height: 100vh !important;
  min-height: 849px;
  width: 100vw !important;
  background-color: #121519 !important;
  text-align: center !important;
  @media (max-width: 1023px) {
    height: 893px;
    // margin-top:-300px;
  }
}

.container-data {
  position: relative;
  height: 849px;
  width: 100%;
  max-width: 1500px;
  margin: auto auto;
}

.data-animation {
  position: relative;
  background-color: #121519 !important;
  width: 100%;
  max-width: 1500px;
  height: 100%;
  /* max-height: 1044px; */
  margin: 0 auto;
  @media (max-width: 1023px) {
    visibility: hidden;
    height: 0px;
    width: 0px;
  }
}

.data-animation-mobile {
  position: relative;
  background-color: #121519 !important;
  width: 100%;
  // margin-left: 44px;
  // margin-top: 300px;
  top: 300px;
  height: 506px;
  /* max-height: 1044px; */
  background-image: url("../../assets/images/data-graph.svg") !important;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  @media (min-width: 1024px) {
    visibility: hidden;
    height: 0px;
    width: 0px;
  }
}

.data-content {
  position: absolute;
  width: 560px;
  height: 192px;
  right: 40px;
  top: 467px;
  z-index: 10;
  @media (max-width: 1023px) {
    width: 297px;
    height: 144px;
    top: 64px;
    left: 24px;
  }
}

.data-header {
  font-family: "HelveticaNeueLtExt";
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 105%;
  color: #ffffff;
  text-align: left;
  margin-bottom: 24px;
  @media (max-width: 1023px) {
    font-size: 30px;
  }
}
.data-text {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;

  /* or 22px */

  /* Grey 100 */
  color: #ffffff;
  text-align: left;
}

.container-orange-bg {
  height: 100vh !important;
  min-height: 1063px;
  width: 100vw !important;
  background-color: #ed7054 !important;
  text-align: center !important;
  @media (max-width: 1023px) {
    min-height: 0;
    height: 700px !important;
  }
}

.container-orange {
  position: relative;
  height: 1063px;
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  @media (max-width: 1023px) {
    height: auto !important;
  }
}

.orange-animation {
  position: relative;
  background-color: #ed7054 !important;
  width: 100%;
  max-width: 1500px;
  height: 634px;
  margin: 0 auto;
  top: 200px;
  @media (max-width: 1023px) {
    visibility: hidden;
    width: 0px;
    height: 0px;
  }
}

.orange-animation-mobile {
  position: relative;
  background-color: #ed7054 !important;
  // width: 356px;
  height: 280px;
  // margin:0 auto;
  top: 178px;
  background-image: url("../../assets/images/orange-graphic.svg") !important;
  @media (min-width: 1024px) {
    visibility: hidden;
    width: 0px;
    height: 0px;
  }
  margin: auto;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: -20px;
}

.orange-header {
  position: absolute;
  margin-top: 131px;
  margin-left: 40px;
  width: 463px;
  height: 132px;
  font-family: "HelveticaNeueLtExt";
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 105%;

  /* or 44px */

  color: #ffffff;
  text-align: left;
  z-index: 10;
  @media (max-width: 1023px) {
    margin-top: 64px;
    left: 24px;
    margin-left: 0;
    font-size: 30px;
    width: 324px;
  }
}

.orange-text {
  position: absolute;
  margin-top: 866px;
  right: 40px;
  font-family: "Clarkson";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;

  /* or 23px */

  color: #ffffff;
  text-align: left;
  width: 454px;
  height: 92px;
  z-index: 10;
  @media (max-width: 1023px) {
    margin-top: 530px;
    left: 24px;
    width: 324px;
    height: 138px;
    right: none;
  }
}

.container-movement {
  background-color: #1a1c25;
  height: 691px;
  min-height: 800px;
  max-height: 1000px;
  @media (max-width: 1023px) {
    height: 525px !important;
    min-height: 525px !important;
  }
}

.card-movement {
  position: absolute !important;
  width: 100vw !important;
  max-width: 1500px !important;
  left: 50%;
  transform: translate(-50%, 0);
  margin-top: 8%;
  height: 500px;
  background-color: #1a1c25;
  text-align: center;
  @media (max-width: 1023px) {
    // position: absolute;
    width: 327px !important;
    height: 174px !important;
    // margin-left: 24px !important;
    margin-top: 120px !important;
    left: 24px;
    transform: translate(0, 0);
    // transform: 0;
    // transform:none;
  }
}

.top-line {
  position: relative !important;
  border: 5px solid rgba(255, 255, 255, 0.05);
  /* top: 27.5%; */
  left: 40%;
  width: 258px;
  /* height: 0px; */
}

.movement-text {
  position: relative !important;
  width: 747px;
  height: 350px;
  left: calc(50% - 747px / 2 - 0.5px);
  top: 70px;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 105%;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #ffffff;
  // bottom: 8%;
  padding-top: 40px;
  padding-bottom: 40px;
  @media (max-width: 1023px) {
    left: 0;
    font-size: 24px;
    text-align: left;
    height: 200px;
    width: 327px;
    padding-top: 40px;
    padding-bottom: 40px;
    // text-align: none;
  }
}

.movement-text::before {
  content: "";
  width: 258px;
  height: 10px;
  background: rgba(255, 255, 255, 0.05);
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -129px;
  @media (max-width: 1023px) {
    left: 0;
    margin-left: 0;
    width: 94px;
    // top: 0;
  }
}

.movement-text::after {
  content: "";
  width: 258px;
  height: 10px;
  background: rgba(255, 255, 255, 0.05);
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -129px;
  @media (max-width: 1023px) {
    left: 0;
    margin-left: 0;
    width: 94px;
  }
}

.bottom-line {
  position: relative !important;
  border: 5px solid rgba(255, 255, 255, 0.05);
  top: 20% !important;
  left: 40%;
  width: 258px;
  height: 0px;
}

.button-movement {
  border-radius: 1px !important;
  position: relative;
  bottom: 0;
  width: auto;
  height: auto;
  // left: 50%;
  // margin-left: -68.5px;
  margin: 0 auto;
  margin-top: 100px;
  background-color: #ffffff !important;
  font-family: "HelveticaNeue";
  font-size: 15px;
  font-style: normal;
  // font-weight: 500;
  line-height: 140%;
  // letter-spacing: 0em;
  text-align: center;
  padding: 12px 40px !important;
  // padding-left: 20px !important;
  color: #121519 !important;
  border-color: #ffffff;
  @media (max-width: 1023px) {
    top: 303px;
    left: 0 !important;
    margin-left: 0 !important  ;
  }
}

.button-movement:hover {
  background: #f9f9f9;
  /* Elevation 3 */
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1), 0px 7px 6px rgba(0, 0, 0, 0.04);
  border-radius: 1px;
  opacity: 0.8;
}

.container-world-bg {
  background: linear-gradient(90deg, #ffffff 50%, #121519 50%);
  width: 100vw;
  height: 1100px;
  @media (max-width: 1023px) {
    position: relative;
    height: 730px;
    background: #ffffff;
  }
}

.container-world {
  height: 100% !important;
  width: 100vw !important;
  max-width: 1500px;
  background-color: #ffffff;
  background-image: url("../../assets/images/worldbg.svg") !important;
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 0 auto;
  @media (max-width: 1023px) {
    width: 100% !important;
    height: 100% !important;
    background-image: none !important;
  }
}

.world-animation {
  background-color: #ffffff !important;
  width: 100%;
  max-width: 1500px;
  height: 100% !important;
  max-height: 1044px !important;
  margin: 0 auto;
}

.world-header {
  position: relative;
  top: 283px;
  font-family: "HelveticaNeueLtExt";
  font-style: normal;
  font-weight: 500;
  font-size: 44px;
  line-height: 105%;

  /* or 46px */
  display: flex;
  align-items: flex-end;

  color: #000000;
  height: 92px;
  width: 406px;
  margin-left: 40px;
  @media (max-width: 1023px) {
    font-size: 30px;
    line-height: 120%;
    top: 64px;
    margin-left: 24px;
    height: 72px;
    width: 319px;
  }
}

.world-text-container {
  top: 400px;
  width: 406px;
  height: auto;
  position: relative;
  padding-left: 97px;
  margin-left: 40px;
  @media (max-width: 1023px) {
    padding-left: 0;
    top: 100px;
    margin-left: 24px;
    width: 333px;
  }
}

.world-text-container::before {
  content: "";
  height: 224px;
  width: 10px;
  background: #dddbdc;
  left: 0px;
  position: absolute;
  @media (max-width: 1023px) {
    visibility: hidden;
    width: 0;
    height: 0;
  }
}

.world-subheader {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;

  /* identical to box height, or 22px */
  display: flex;
  align-items: flex-end;

  color: #000000;
}

.world-text {
  margin-top: 16px;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;

  /* or 22px */

  /* Grey 800 */
  color: #333333;
  width: 406px;
  height: auto;
  @media (max-width: 1023px) {
    width: 100%;
  }
}

.world-learnmore {
  position: relative;
  margin-top: 17px;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 140%;

  /* identical to box height, or 21px */
  display: flex;
  align-items: flex-end;
  text-transform: uppercase;

  /* Grey 900 */
  color: #121519;
  padding-right: 12px;
  width: 135px;
  height: 21px;
  @media (max-width: 1023px) {
    visibility: hidden;
    width: 0;
    height: 0;
  }
}

.world-learnmore::after {
  position: absolute;
  width: 24px;
  height: 24px;
  content: "";
  background: url("../../assets/images/Right-Enabled.svg") !important;
  right: 0;
}

.world-mobile {
  position: absolute;
  width: 100%;
  height: 293px;
  display: inline-block;
  background: linear-gradient(90deg, #efefef 50%, #121519 50%);
  top: 445px;
  @media (min-width: 1024px) {
    width: 0;
    height: 0;
    visibility: hidden;
  }
}

.world-mobile-image {
  margin-top: 24px !important;
  width: 246px;
  height: 246px;
  margin: 0 auto;
  // background: url("../../assets/images/thankyouglobe.svg");
  // background-position: center;
  // background-size: 100%;
  // background-repeat: no-repeat;
  @media (min-width: 1024px) {
    width: 0;
    height: 0;
    visibility: hidden;
  }
}

.container-negotiate-bg {
  width: 100vw;
  height: 900px;
  background-color: #1a1c25;
  @media (max-width: 1023px) {
    height: 625px !important;
  }
}

.container-negotiate {
  width: 100%;
  max-width: 1500px;
  height: 100%;
  margin: 0 auto;
  @media (max-width: 1023px) {
    width: 100%;
    height: 100%;
  }
}

.header-negotiate {
  margin-top: 300px;
  margin-left: 40px;
  position: relative;
  width: 400px;
  height: 138px;
  font-family: "HelveticaNeueLtExt";
  font-style: normal;
  font-weight: 500;
  font-size: 44px;
  line-height: 105%;

  /* or 46px */
  display: flex;
  align-items: flex-end;
  text-align: left;

  /* Grey 100 */
  color: #ffffff;
  padding-bottom: 52px;
  @media (max-width: 1023px) {
    margin-top: 150px !important;
    font-size: 30px;
    width: 323px;
    height: 108px;
    line-height: 120%;
    margin-left: 24px;
    padding-bottom: 50px;
  }
}

.header-negotiate::after {
  position: absolute;
  content: "";
  width: 364px;
  height: 10px;
  background: #333333;
  bottom: 0;
  @media (max-width: 1023px) {
    width: 80px;
  }
}

.negotiate-content {
  height: 249px;
  width: 476px;
  margin: 0 auto;
  margin-top: 400px;
  @media (max-width: 1023px) {
    width: 329px;
    height: 407px;
    margin-top: 32px;
    margin-left: 24px;
  }
}

.negotiate-subheader {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;

  /* identical to box height, or 22px */
  display: flex;
  align-items: flex-end;

  /* Grey 100 */
  color: #ffffff;
}

.negotiate-text {
  margin-top: 16px;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;

  /* or 22px */

  /* Grey 100 */
  color: #ffffff;
}

.negotiate-button {
  margin-top: 56px;
  width: auto;
  height: auto;
  background: #60d78a;
  border-color: #60d78a;

  /* Elevation 3 */
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1), 0px 7px 6px rgba(0, 0, 0, 0.04);
  border-radius: 1px;
  font-family: "HelveticaNeue";
  // font-style: bold;
  font-weight: 500;
  font-size: 15px;
  line-height: 140%;

  // /* identical to box height, or 21px */
  // display: flex;
  // align-items: flex-end;
  // text-align: center;
  // text-transform: uppercase;

  /* Grey 900 */
  color: #121519;
  padding: 12px 40px !important;
}

.negotiate-button:hover {
  background: #55bc7a !important;
  /* Elevation 3 */
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1), 0px 7px 6px rgba(0, 0, 0, 0.04);
  border-radius: 1px;
  border-color: #55bc7a;
  color: #121519;
}

.container-circle-bg {
  width: 100vw;
  height: 650px;
  background-color: #ffffff;
  @media (max-width: 1023px) {
    height: 500px;
  }
}

.container-circle {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  height: 100%;
  @media (max-width: 1023px) {
    width: 100%;
    height: 100%;
  }
}

.circle-text-container {
  position: absolute;
  left: calc(50% - 680px / 2);
  width: 680px;
  height: 680px;
  /* or 46px */
  @media (max-width: 1023px) {
    left: 27px;
    margin-top: 118px;
    width: 322px;
    height: 322px;
    background-size: 100%;
  }
}

.circle-text {
  position: relative;
  width: 554px;
  height: 184px;
  left: calc(50% - 554px / 2 + 2px);
  top: 248px;
  font-family: "HelveticaNeueLtExt";
  font-style: normal;
  font-weight: normal;
  font-size: 44px;
  line-height: 105%;
  display: flex;
  align-items: flex-end;
  text-align: center;

  color: #121519;
  @media (max-width: 1023px) {
    width: 271px;
    left: 24px;
    top: 64px;
    font-size: 24px;
    align-items: center;
  }
}

.container-claims-bg {
  width: 100vw;
  height: 800px;
  background-color: #121519;
}

.container-claims {
  width: 100%;
  max-width: 1500px;
  height: 100%;
  margin: 0 auto;
  @media (max-width: 1023px) {
    height: 755px;
  }
}

.container-claims-text {
  height: 364px;
  width: 416px;
  margin-left: 40px;
  margin-top: 200px;
  @media (max-width: 1023px) {
    margin-top: 64px;
    margin-left: 24px;
    width: 329px;
  }
}

.claims-header {
  font-family: "Clarkson";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;

  /* or 23px */
  display: flex;
  align-items: flex-end;

  /* Grey 100 */
  color: #ffffff;
}

.claims-subheader {
  margin-top: 36px;
  font-family: "HelveticaNeueLtExt";
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 105%;

  /* or 44px */
  display: flex;
  align-items: flex-end;

  /* Grey 100 */
  color: #ffffff;
  @media (max-width: 1023px) {
    font-size: 30px;
  }
}

.claims-text {
  position: relative;
  margin-top: 10px;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;

  /* or 22px */

  /* Grey 200 */
  color: #f9f9f9;
  padding-bottom: 40px;
  @media (max-width: 1023px) {
    display: inline-block;
  }
}

.claims-text::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.05);
  left: 0;
}

.claims-seemore {
  width: 300px;
  height:24px;
  position: relative;
  margin-top: 36px;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 140%;

  /* identical to box height, or 21px */
  text-transform: uppercase;

  /* Grey 100 */
  color: #ffffff;
  padding-right: 12px;
}

.claims-seemore::after {
  position: absolute;
  content: "";
  right: 12px;
  width: 24px;
  height: 24px;
  background-image: url("../../assets/images/Right-Arrow-White.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}

.equifax-claim {
  // position: absolute;
  margin: 0 auto;
  margin-right: 40px;
  width: 372px;
  height: 444px;
  margin-top: 160px;
  background-image: url("../../assets/images/equifax.svg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1px;
  @media (max-width: 1023px) {
    // position: absolute;
    width: 244px;
    height: 265px;
    display: inline-block;
    left: 40px;
    margin-top: 48px;
    margin-left: 24px;
  }
}

.claim-home-container {
  width: 252px;
  height: 248px;
  margin-left: 60px;
  margin-top: 98px;
  @media (max-width: 1023px) {
    height: 181px;
    width: 212px;
    margin-left: 16px;
    margin-top: 48px;
  }
}

.claim-home-deadline-header {
  font-family: "Clarkson";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;

  /* identical to box height, or 20px */

  /* Light 1 */
  color: rgba(255, 255, 255, 0.8);
  @media (max-width: 1023px) {
    font-size: 12px;
  }
}

.claim-home-deadline {
  font-family: "Clarkson";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;

  /* identical to box height, or 20px */

  /* Light 1 */
  color: #ffffff;
}

.claim-home-header {
  margin-top: 25px;
  position: relative;
  font-family: "HelveticaNeueLtExt";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 120%;

  /* identical to box height, or 36px */

  color: #ffffff;
  padding-bottom: 25px;
  @media (max-width: 1023px) {
    font-size: 24px;
  }
}

.claim-home-header::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 57px;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.2);
}

.claim-home-payout-header {
  margin-top: 74px;
  font-family: "Clarkson";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;

  /* identical to box height, or 20px */

  /* Light 1 */
  color: rgba(255, 255, 255, 0.8);
  @media (max-width: 1023px) {
    margin-top: 25px;
    font-size: 11px;
  }
}

.claim-home-payout {
  font-family: "Clarkson";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;

  /* identical to box height, or 20px */

  /* Light 1 */
  color: #ffffff;
}
