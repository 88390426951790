.container-question {
	// position: absolute;
	background: transparent;
	color: black;
	width: 67.67%;
	height: 56%;
	margin: 0 auto;
	margin-top: 10%;
}

.question-header {
	// margin-top:42%;
	font-family: "ITCBerkeleyStandard";
	font-style: normal;
	font-weight: 500;
	font-size: 25px;
	line-height: 120%;
	max-width: 600px;
	text-align: left;
	color: #000000;
	margin: auto;
	@media (max-width: 1023px) {
		font-size: 24px;
	}
}

.question-text {
	font-family: "ITCBerkeleyStandard";
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 23px;
	max-width: 600px;
	text-align: left;
	margin: auto;
	margin-bottom: 30px;
	margin-top: 15px;
	@media (max-width: 1024px) {
		font-size: 16px;
		line-height: 19px;
		margin-bottom: 20px;
		margin-top: 10px;
	}
}

.question-row-mode {
	flex-direction: column;
	margin: auto;
}

.question-col-mode {
	flex-direction: row;
	margin: 0 auto;
	align-items: center;
	text-align: center;
	width: 100%;
	max-width: 900px;
	@media screen and (max-width: 1024px) {
		max-width: 98vw;
	}
}

.question-col-full {
	font-family: "HelveticaNeue";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 121%;
	// max-width: 530px;
	width: 411px;
	background: #f2f2f2;
	border-radius: 50px;
	margin: 0 auto;
	text-align: center;
	margin: auto;
	margin-bottom: 10px;
	height: 90px;
	vertical-align: middle;
	flex-direction: column;
	cursor: pointer;
	@media (max-width: 1024px) {
		flex-direction: row !important;
		width: 100%;
		height: 85px;
	}
}

.question-reset {
	background-image: url("../../../../assets/images/refresh.svg") !important;
	background-position: center;
	background-repeat: no-repeat;
	// position: absolute;
	// top: 60px;
	// left: 200px;
	height: 28px;
	width: 28px;
	background-size: contain;
	cursor: pointer;
	// @media (max-width: 1024px) {
	// 	top: 10px;
	// 	left: 10px;
	// 	height: 25px;
	// 	width: 25px;
	// }
	margin-bottom: 24px;
}

.question-reset .question-reset-tooltip {
	visibility: hidden;
	width: 120px;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	font-size: 0.8rem;
	/* Position the tooltip */
	// position: absolute;
	z-index: 1;
	top: -5px;
	left: 105%;
}

.question-reset:hover .question-reset-tooltip {
	visibility: visible;
}

.question-col-half {
	max-width: 222px;
	width: 49vh;
	background: rgba(0, 255, 102, 0.35);
	margin: auto;
	text-align: center;
	height: 290px;
	margin: 0 auto !important;
	margin-left: 5px !important;
	margin-right: 5px !important;
	flex-direction: column;
	cursor: pointer;
	@media (max-width: 1024px) {
		flex-direction: column;
		max-width: 140px;
		width: 46%;
		height: 140px;
	}
}

.question-answer-header {
	// font-family: Quantico;
	font-family:"HelveticaNeue";
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 30px;
	color: black;
	text-transform: uppercase;
	// padding: 10px;
	width: 98%;
	@media screen and (max-width: 1024px) {
		font-size: 20px;
		line-height: 24px;
		// padding-left: 5px;
		width: 100%;
	}
}

.question-answer-desc {
	// font-family: Quantico;
	font-family:"HelveticaNeue";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	color: black;
	// padding-left: 10px;
	width: 98%;
	@media screen and (max-width: 1024px) {
		font-size: 12px;
		line-height: 14px;
		// padding-left: 5px;
		width: 100%;
	}
}

.border-green {
	border: 2px solid #121519;
	// box-sizing: border-box;
	border-radius: 50px;
}

.button-margin {
	margin-top: 25px;
}

.question-button {
	background-color: #60d78a !important;
	font-family: "Roboto Condensed" !important;
	font-size: 16px !important;
	font-weight: bold !important;
	font-stretch: condensed !important;
	letter-spacing: 1.6px !important;
	color: #121212 !important;
	outline: none !important;
	border: none !important;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06) !important;
	margin-top: 10px !important;
	margin-right: 10px !important;
	min-width: 150px;
	z-index: 100;
	// @media (max-width: 1023px) {
	// 	margin: 0 auto;
	// }
}

.question-button:hover {
	background-color: #55bc7a !important;
}

.question-external-arrow {
	background-image: url("../../../../assets/images/outbound-link.svg") !important;
	background-position: center;
	background-repeat: no-repeat;
	margin: auto;
	width: 50px;
	height: 50px;
	text-align: center;
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 50px;
	margin-top: 25%;
}
