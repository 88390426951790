.container-privacy-bg {
	width:100vw;
	height:100%;
}
.container-privacy {
	font-family: 'Roboto Condensed';
	background-color: #f9f9f9 !important;
	color: black;
	width:100%;
	max-width: 1500px;
	min-height: 550px;
	padding: 0 !important;
	margin: 0 auto;
	margin-top:200px;
}

.content-container {
	font-family: 'Roboto Condensed';
	background-color: #f9f9f9 !important;
	color: black;
	padding: 0 15px !important;
}

.privacy-h5 {
	position: relative !important;
  font-family: 'HelveticaNeue';
  font-weight: bold;
  line-height: 0.96;
  letter-spacing: normal;
  color: #121212;
	width: 95%;
	padding: 15px  15px !important;
	padding-top :15px !important;
	padding-bottom:15px !important;
	margin-bottom: 0px !important;
}

.privacy-text {
	font-family: Roboto Condensed;
	font-size: 14px;
	font-stretch: condensed;
	color: #121212;
	text-align: left;
	width: 100%;
	padding: 0;
	margin-bottom:0px;
	padding-left: 15px !important;
	padding-right: 12px !important;
}

.privacy-title {
  min-height: 35px;
  font-family: Quantico;
  font-size: 34px;
  font-weight: bold;
  line-height: 1.03;
  color: #000000;
	text-align: left;
	width: 100%;
	margin-bottom :0px;
	padding-left: 15px !important;
	padding-right: 12px !important;
	padding-top:25px;
	padding-bottom:25px;
}

.privacy-tab {
	margin-top: 20px;
	padding-left:30px;
	border: none;
	background-color: #f9f9f9 !important;
	box-shadow: none;
}

.privacy-tab .nav-item {
  font-size: 16px;
	font-weight: bold;
	color: black !important;
	padding-left:0px;
}

.privacy-tab .nav-link.active:hover {
	color: #55bc7a !important;
}

.privacy-tab .nav-link:hover {
	color: #55bc7a !important;
}

.privacy-tab .nav-link.active {
	color: black !important;
	background-color: #f9f9f9 !important;
	border-bottom: none !important;
}

.privacy-border-table {
	border: 1px solid black;
	border-collapse: collapse;
}

.privacy-markdown-container {
	margin-left:40px;
  h2 {
    position: relative !important;
    min-height: 46px;
    font-family: 'HelveticaNeueLtExt';
    font-size: 36px;
    font-weight: bold;
    letter-spacing: normal;
    color: #121212;
    width: 95%;
    margin-top: 12px !important;
    margin-bottom: 20px !important;
		@media screen and (max-width: 1024px) {
			font-size: 24px;
		}
  }
	h3 {
		min-height: 35px;
		font-family: 'HelveticaNeue';
		font-size: 20px;
		font-weight: bold;
		line-height: 1.03;
		color: #000000;
		text-align: left;
		width: 100%;
		padding-left: none !important;
    margin-bottom: 12px !important;
  }
  p {
    font-family: "Clarkson";
    font-size: 14px;
    font-stretch: condensed;
    color: #121212;
    text-align: left;
    width: 100%;
    padding: 0;
		margin-bottom: 16px;
    @media screen and (min-width: 1025px) {
      margin-bottom: 22px;
    }
  }
  li {
    font-family: "HelveticaNeue" !important;
    font-size: 14px;
    color: #121212;
    padding-bottom: 12px;
  }
  ol {
    padding: 0 13px !important;
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-stretch: condensed;
  }
	td {
		font-family: "HelveticaNeue";
		font-size: 14px;
		font-stretch: condensed;
		color: #121212;
	}
}