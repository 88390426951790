.container-cladetails-background {
	background-color: #ffffff;
	color: black;
	width: 100vw;
	height:auto;
	@media (max-width:1023px) {
		overflow: hidden !important;
	}
}

.container-cladetails {
	width: 100%;
	max-width: 1500px;
	margin: 0 auto;
	min-height:100vh;
	max-height:auto;
	// max-height: 740px;
}

.container-claimbg {
	width: 42%;
	height: 100%;
	background-image: url("../../../assets/images/equifax.svg") !important;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	@media (max-width: 1023px) {
		width: 0px;
		height: 0px;
		visibility: hidden;
	}
}

.container-claimbg-mobile {
	width: 343px;
	margin-top: 136px;
	margin-left: 24px;
	@media (min-width: 1024px) {
		width: 0px;
		height: 0px;
		visibility: hidden;
	}
}

.grey-background {
	background-color: #f2f2f2 !important;
}

.container-lawsuit {
	position: relative;
	background: transparent;
	color: black;
	width: 404px;
	height: 452px;
	margin-left: 60px;
	margin-top: 40%;
}

.deadline {
	font-family: "Clarkson";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 140%;
	color: rgba(255, 255, 255, 0.8);
}

.days-left {
	font-family: "Clarkson";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 130%;
	color: #ffffff;
}

.lawsuit-company {
	margin-top: 25px;
	font-family: "HelveticaNeueLtExt";
	font-style: normal;
	font-weight: 500;
	font-size: 42px;
	line-height: 105%;
	color: #ffffff;
}

.lawsuit-info {
	position: relative;
	margin-top: 25px;
	width: 404px;
	padding-top: 25px;
	font-family: "HelveticaNeue";
	font-style: normal;
	font-weight: normal;
	font-size: 22px;
	line-height: 140%;
	/* or 22px */

	/* Grey 200 */
	color: #f9f9f9;
}

.lawsuit-info::before {
	content: "";
	width: 57px;
	height: 2px;
	background: #ffffff;
	position: absolute;
	top: 0;
	// border-bottom: 1px solid #0000CC;
}

.lawsuit-payout-title {
	margin-top: 74px;
	height: 20px;
	position: relative;
	font-family: "Clarkson";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 140%;
	color: rgba(255, 255, 255, 0.8);
	display: inline-block;
}

.lawsuit-payout-amount {
	position: relative;
	top: 0;
	font-family: "Clarkson";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 130%;

	/* or 23px */

	color: #ffffff;
}

.cla-faq-heading {
	position: relative !important;
	font-family: "HelveticaNeueLtExt";
	font-style: normal;
	font-weight: 500;
	font-size: 29px;
	line-height: 120%;

	/* identical to box height, or 29px */
	display: flex;
	align-items: flex-end;

	color: #000000;

	// width: 95%;
	text-align: left;
	// padding: 12px 35px !important;
	margin-bottom: 18px;
	margin-top: 120px;
}

.lawsuit-company-col {
	align-items: left;
	text-align: left;
	padding: 0 !important;
	width: 65%;
}

.container-survey {
	width: 58%;
	height: 100%;
	background-color: #ffffff;
	margin-top: 50px;
	@media (max-width: 1023px) {
		width: 100%;
		// height:0px;
		// visibility: hidden;
		margin-top: 150px;
	}
}

.clafaq-container {
	background-color: #ffffff !important;
	color: black;
	width: 60% !important;
	height: 100%;
	min-height: 550px;
	padding: 0 !important;
	margin: 0 auto !important;
	text-align: center;
		
	@media (max-width: 1023px) {
		margin-top: -100px !important;
		width: 343px !important;
	}
}

.faq-card-text {
	// font-family: Roboto Condensed;
	font-size: 14px;
	font-stretch: condensed;
	color: #121212;
	text-align: left;
	width: 100%;
	// padding: 0.5rem 23px;
	@media screen and (min-width: 1025px) {
		font-size: 16px;
	}
	@media (max-width: 1023px) {
		padding: 0;
	}
	p {
		font-size: 14px;
		@media screen and (min-width: 1025px) {
			font-size: 16px;
		}
	}
}

.faq-card-title {
	min-height: 35px;
	font-family: Quantico;
	font-size: 17px;
	font-weight: bold;
	line-height: 1.03;
	color: #000000;
	text-align: left;
	width: 100%;
	cursor: pointer;
	padding-left: none !important;
}

.faq-card-header {
	border: none !important;
	background-color: white !important;
	padding: 6.5px 11.5px !important;
}

.cla-social-div {
	margin: auto;
	width: 100%;
	text-align: center;
	align-items: center;
	justify-content: center;
	top: 335px;
	display: flex;
}

.lawsuit-learnmore {
	// position: relative;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 58px;
	text-align: center;
	// margin: 0 auto;
	font-family: "HelveticaNeue";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 140%;

	/* identical to box height, or 20px */

	/* Grey 100 */
	color: #ffffff;

	width: 74px;
	height: 52px;
	background-image: url("../../../assets/images/Down.svg") !important;
	background-position: bottom;
	// background-size:100%;
	background-repeat: no-repeat;
}

.card-body {
	padding-left:0px;
}