.finalstep-col {
	background-image: url("../../../../assets/images/finalstep-squibble.svg") !important;
  background-position: center;
  background-repeat: no-repeat;
	height: 300px;
	width: 260px;
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 23px;
	text-align: center;
	margin: 20px;
	padding: 10px;
	background: #FFFFFF;
	border: 1px solid #F2F2F2;
	box-sizing: border-box;
	@media screen and (max-width: 1024px) {
		font-size: 14px;
		line-height: 17px;
		width: 100%;
		height: 185px;
		padding: 5px;
		margin: 10px;
	}
}

.finalstep-col-header {
	font-family: 'HelveticaNeueLtExt';
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 30px;
}

.finalstep-col-text {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 22px;
	color: #000000;
	margin-bottom: 60px;
	@media screen and (max-width: 1024px) {
		margin-bottom: 15px;
	}
}

.finalstep-col-mode  {
	flex-direction: row;
	margin: 0 auto;
	align-items: center;
	text-align: center;
	width: 100%;
	max-width: 900px;
	@media screen and (max-width: 1024px) {
		flex-direction: column;
		max-width: 98vw;
	}
}

.final-black-rectangle {
	width: 40px ;
  height: 40px;
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.053267) !important;
  background-color: #000000 !important;
	margin: 5px;
	cursor: pointer;
}

.final-social-div {
	height: 75px;
	width: 98%;
	text-align: center;

}