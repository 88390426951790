.signup-container-background {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #1a1c25 43%, #ffffff 43%);
  @media (max-width: 1023px) {
    background: none;
    height: auto !important;
  }
  overflow: hidden !important;
}

.signup-container {
  width: 100%;
  height: 100%;
  max-width: 1500px;
  margin: 0 auto;
  @media (max-width: 1023px) {
    width: 100%;
    height: 100%;
  }
}

.progress-container {
  width: 100%;
  margin: 20px auto;
  max-width: 504px;
  margin-top: 15px;
  @media (max-width: 1023px) {
    width: 320px;
    margin: 20px 0px 20px 20px;
  }
}

.signup-step-container {
  margin: auto;
  width: 504px;
  height: 100%;
  @media (max-width: 1023px) {
    width: 100% !important;
    height: auto !important;
    // display: inline-block;
    text-align: left;
    margin-left: 16px;
  }
  @media (max-width: 375px) {
    width: 320px !important;
  }
}

.signup-information {
  width: 43%;
  height: 100%;
  margin-top: 9%;
  margin-bottom: 3%;
  @media (max-width: 1023px) {
    width: 100%;
    height: 364px;
    display: inline-block;
    text-align: left;
    background-color: #1a1c25;
  }
  @media (max-width: 420px) {
    width: 100%;
    height: 420px;
    display: inline-block;
    text-align: left;
    background-color: #1a1c25;
  }
}

.signup-text-container {
  width: 100%;
  max-width: 403px;
  height: 248px;
  margin-top: 24.86% !important;
  /* max-height:248px; */
  @media (max-width: 1023px) {
    margin-top: 98px !important;
  }
}

.signup-header {
  position: relative;
  font-family: "HelveticaNeueLtExt";
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 105%;
  color: #ffffff;
  margin-left: 11.57%;
  padding-bottom: 25px;
  @media (max-width: 1023px) {
    font-size: 24px;
    margin-left: 24px;
  }
}

.signup-header::after {
  content: "";
  width: 57px;
  height: 2px;
  background: #ffffff;
  position: absolute;
  bottom: 0;
}

.signup-subheader {
  margin-top: 25px;
  width: 403px;
  height: 66px;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  color: #f9f9f9;
  margin-left: 11.57%;
  @media (max-width: 1023px) {
    margin-left: 24px;
    width: 327px;
    height: 88px;
  }
}

.signup-step-wrapper {
  height: 100%;
  width: 57%;
  margin-top: 9%;
  margin-bottom: 5%;
  background-color: #ffffff !important;
  /* padding-left:7.79% !important; */
  @media (max-width: 1023px) {
    width: 100%;
    height: auto;
    display: inline-block;
  }
}

.bg-success {
  background-color: #60D78A;
  border-radius: 20px;
}

.progress-bar-border {
  border-radius: 20px;
}

.information-form {
  /* position: absolute; */
  height: 514px;
  width: 474px;
  /* left: 90px !important; */
  @media (max-width: 1023px) {
    width: 343px;
    height: 755px;
  }
}

.form-group-right {
  margin-right: 0 !important;
  width: 227px !important;
  height: 52px !important;
  /* display: block !important; */
  /* float: right !important; */
}

.half-input {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  padding: 19px 24px;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 140%;
  color: #121519;
  width: 227px !important;
  height: 52px !important;
  @media (max-width: 1023px) {
    width: 95% !important;
    max-width: 163.5px !important;
    height: 52px !important;
  }
  @media (max-width: 375px) {
    //width: 100% !important;
    max-width: 145px !important;
    height: 52px !important;
  }
}

.full-input-error {
  padding: 5px 26px;
  font-family: "HelveticaNeue";
  max-width: 100% !important;
  min-height: 52px !important;
  @media (max-width: 1023px) {
    padding: 2.5px 13px;
    width: 100% !important;
    height: 52px !important;
  }
  @media (max-width: 375px) {
    //width: 100% !important;
    max-width: 290px !important;
    height: 52px !important;
  }
}

.half-input-error {
  padding: 5px 26px;
  font-family: "HelveticaNeue";
  max-width: 227px !important;
  height: 52px !important;
  @media (max-width: 1023px) {
    padding: 2.5px 13px;
    width: 163.5px !important;
    height: 52px !important;
    font-size: 11px;
  }
  @media (max-width: 375px) {
    //width: 100% !important;
    max-width: 145px !important;
    height: 52px !important;
  }
}

.input-right {
  margin-left: 16px;
}

.full-input {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  padding: 19px 24px;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 140%;
  color: #121519;
  width: 470px !important;
  min-height: 52px !important;
  max-width: 470px !important;
  @media (max-width: 1023px) {
    //width: 100% !important;
    max-width: 343px !important;
    height: 52px !important;
  }
  @media (max-width: 375px) {
    //width: 100% !important;
    max-width: 300px !important;
    height: 52px !important;
  }
}

.form-group-signup {
  min-height: 52px;
  max-height: 100px;
  margin-bottom: 0 !important;
  /* display: inline-block !important; */
  /* float: left; */
  margin-left: 0px !important;
  margin-right: 0px !important;

  display: flex !important;
}
.form-check-green {
  margin-left: 0;
  width: 470px;
  height: 36px;
}

.form-check-box-green {
  /* transform : scale(1.8); */
}

.float-right {
  float: right;
}

.info-next-button {
  float: right;
  width: 121px;
  height: 45px;
  background-color: #5cdf8b;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 140%;
  padding: 12px 40px;
  /* identical to box height, or 21px */
  display: flex;
  align-items: flex-end;
  text-align: center;
  text-transform: uppercase;
  border-radius: 1px;
  border-color: #5cdf8b;
  /* Grey 900 */
  color: #121519;
}

.info-next-button:disabled {
  background-color: #e5e5e5;
  color: #121519;
  border-color: #e5e5e5;
}

.verifyphone-container {
  position: relative;
  background-color: #ffffff !important;
  margin: 0 auto;
  margin-top: 25%;
  width: 417px;
  height: 350px;
  @media (max-width: 1023px) {
    height: 250px;
  }
}

.verify-number {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 120%;
  color: #121519;
}

.verifycode-input-row {
  margin-left: 0 !important;
  margin-top: 5%;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1023px) {
    flex-direction: row !important;
  }
}

.verifycode-input {
  width: 56px;
  height: 58px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 2px;
  margin-right: 9px;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  color: #121519;
  text-align: center;
  @media (max-width: 1023px) {
    width: 44px;
    height: 44px;
  }
}

.resend-button {
  background-color: transparent;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 140%;

  /* identical to box height, or 21px */
  text-transform: uppercase;

  /* Grey 800 */
  color: #333333;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  color: black;
  text-align: center;
}

.resend-button:hover,
.resend-button:focus {
  text-decoration: underline;
  background-color: transparent;
  outline: none;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 140%;

  /* identical to box height, or 21px */
  display: flex;
  align-items: flex-end;
  text-transform: uppercase;

  /* Grey 700 */
  color: #8a8e95;
}

.verify-button {
  position: absolute;
  bottom: 0;
  width: 121px;
  height: 45px;
  background: #5cdf8b;
  border-radius: 1px;
  border-color: #5cdf8b;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 140%;
  color: #121519;
  padding: 12px 40px;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 1023px) {
    left: 0px;
    transform: none;
    margin-bottom: 20px;
  }
}

.verify-button:hover {
  background: #55bc7a;
  color: #121519;
  /* Elevation 3 */
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1), 0px 7px 6px rgba(0, 0, 0, 0.04);
  border-radius: 1px;
  border-color: #55bc7a;
}

.verify-button:disabled {
  background-color: #e5e5e5;
  color: #121519;
  border-color: #e5e5e5;
}

.container-emails {
  background-color: #ffffff !important;
  margin-top: 5% !important;
  height: 100%;
  @media (max-width: 1023px) {
    margin-top: 5px !important;
    width: 343px;
    margin-left: 0;
    min-height: 350px;
    height: auto;
  }
}

.emails-heading {
  font-family: "HelveticaNeue";
  font-style: bold;
  /* font-weight: 500; */
  font-size: 18px;
  line-height: 120%;
  color: #121519;
}

.field-input-email {
  width: 227px;
  height: 52px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 2px;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;

  /* or 14px */
  text-transform: uppercase;

  /* Grey 900 */
  color: #121519;
}

.field-input-email:hover,
.field-input-email:focus {
  border: 2px solid #121519 !important;
  box-sizing: border-box;
  border-radius: 2px !important;
}

.form-control:focus {
  border-color: #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.button-emails {
  position: absolute;
  /* bottom: 0; */
  /* left: 46%; */
  /* right: 0; */
  /* margin-left: auto;
  margin-right: auto; */
  width: 145px;
  height: 45px;
  background: #5cdf8b;
  border-radius: 1px;
  border-color: #5cdf8b;
  font-family: "HelveticaNeue";
  font-size: 15px;
  font-style: normal;
  font-weight: bold;
  line-height: 140%;
  letter-spacing: 0em;
  text-align: center;
  color: #121519;
  padding: 12px 40px;
  @media (max-width: 1023px) {
  }
}

.button-addemails {
  padding: 12px 20px !important;
}

.button-emails:hover {
  background: #55bc7a;
  color: #121519;
  /* Elevation 3 */
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1), 0px 7px 6px rgba(0, 0, 0, 0.04);
  border-radius: 1px;
  border-color: #55bc7a;
}

.container-signature {
  height: 100%;
  background-color: #ffffff !important;
  position: relative;
  @media (max-width: 1023px) {
    width: 343px;
    height: 542px;
    margin-left: 0px;
  }
}

.form-signature {
  height: 100%;
  width: 100%;
  position: relative;
}

.signature-heading {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 14pt;
  line-height: 140%;
  color: #333333;
  /* background-color:#f9f9f9 !important; */
  @media (max-width: 1023px) {
    font-size: 14px;
  }
}

.resend-button-signature {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #333333;
  border-bottom: 0.5px solid #333333;
  padding-bottom: 3px;
  text-decoration: none;
  border-radius: 0;
}

.resend-button-signature:hover,
.resend-button-signature:focus {
  /* text-decoration: underline; */
  background-color: transparent;
  outline: none;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;

  /* identical to box height, or 21px */
  display: flex;
  align-items: flex-end;
  text-transform: uppercase;

  /* Grey 700 */
  color: #8a8e95;
  border-bottom: 0.5px solid #333333;
  padding-bottom: 3px;
  text-decoration: none;
  border-radius: 0;
}

.signature-pad {
  width: 100%;
  max-width: 470px;
  height: 120px;
  border: 2px solid #e5e5e5;
  background-color: #ffffff;
  border-radius: 2px;
}

.signature-subheading {
  font-family: "HelveticaNeue" !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 140%;

  /* or 22px */

  /* Grey 800 */
  color: #333333;
}

.field-input-signature {
  /* width: 470px !important;  */
  height: 52px !important;
  border: 2px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 2px;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  max-width: 470px;
  /* or 14px */
  text-transform: uppercase;

  /* Grey 900 */
  color: #121519;
}

.field-input-signature:hover,
.field-input-signature:focus {
  border: 2px solid #121519 !important;
  box-sizing: border-box;
  border-radius: 2px !important;
}

.button-signature {
  position: absolute;
  width: 151px;
  height: 45px;
  /* bottom:0; */
  right: 0;
  background: #5cdf8b;
  border-radius: 1px;
  border-color: #5cdf8b;
  font-family: "HelveticaNeue";
  font-size: 15px;
  font-style: normal;
  font-weight: bold;
  line-height: 140%;
  letter-spacing: 0em;
  text-align: center;
  color: #121519;
  padding: 12px 30px;
}

.button-signature:hover {
  background: #55bc7a;
  color: #121519;
  /* Elevation 3 */
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1), 0px 7px 6px rgba(0, 0, 0, 0.04);
  border-radius: 1px;
  border-color: #55bc7a;
}

.clearsignature-button {
  background-color: white !important;
  width: 42px;
  border: 1px #f9f9f9;
  border-radius: 5px !important;
  height: 20px;
  cursor: pointer;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 140%;

  /* identical to box height, or 21px */
  text-transform: uppercase;

  /* Grey 800 */
  color: #333333;
}

.clearsignature-button:hover {
  background-color: #f9f9f9 !important;
}

.clearsignature-overlay {
  width: 48px;
  height: 20px;
  border: none;
  float: right;
  position: sticky;
}

.verify-info {
  width: 470px !important;
  max-width: 470px !important;
  @media (max-width: 1023px) {
    //width: 100% !important;
    max-width: 343px !important;
  }
  @media (max-width: 375px) {
    //width: 100% !important;
    max-width: 300px !important;
  }
}