@media (min-width: 1025px) {
  p {
    font-size: 18px;
  }
}
.container-classaction-background {
  width: 100vw;
  height: 70vh;
  background-image: url("../../../assets/images/classactionbg.png") !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  @media (max-width: 1023px) {
    width: 100%;
    height: 426px;
    z-index: 10;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
.container-classaction {
  position: relative;
  width: 100%;
  max-width: 1500px;
  height: 100%;
  margin: 0 auto;
  @media (max-width: 1023px) {
    width: 100%;
    height: 100%;
  }
}

.header-classaction {
  position: absolute;
  width: 458px;
  height: 138px;
  font-family: "HelveticaNeueLtExt";
  font-style: normal;
  font-weight: 500;
  font-size: 44px;
  line-height: 105%;

  /* or 46px */
  display: flex;
  align-items: flex-end;

  /* White */
  color: #ffffff;
  left: 40px;
  bottom: 30px;
  @media (max-width: 1023px) {
    font-size: 42px;
    height: 176px;
    width: 327px;
    bottom: 24px;
    left: 16px;
  }
}

.container-actnow-background {
  width: 100vw;
  text-align: center;
  background-color: #ffffff !important;
}

.container-actnow {
  position: relative;
  width: 100%;
  max-width: 1500px;
  background-color: #ffffff !important;
  @media (max-width: 1023px) {
    width: 100%;
  }
}

.card-landing-line {
  position: relative;
  color: black;
  width: 100%;
  padding: 0;
  margin-top: 25px;
  border: none;
}

.landing-line {
  font-family: "Quantico";
  font-size: 16px;
  font-weight: 300;
  line-height: 1.25;
  letter-spacing: normal;
  color: #121212;
  text-align: center;
  @media (min-width: 1025px) {
    font-size: 18px;
    margin-top: 15px;
  }
}

.card-landing-buttons {
  position: absolute;
  text-align: center;
  width: 95vw;
  @media (min-width: 320px) and (max-width: 369px) {
    top: calc(25% / 2 + 163.5px);
  }
  @media (min-width: 370px) and (max-width: 399px) {
    top: calc(25% - 10px / 2 + 163.5px);
  }
  @media (min-width: 400px) and (max-width: 759px) {
    top: calc(25% - 23px / 2 + 163.5px);
  }
  @media (min-width: 760px) and (max-width: 1024px) {
    top: calc(50% - 23px / 2 + 138.5px);
  }
  @media (min-width: 1025px) {
    top: calc(50% - 23px / 2 + 138.5px);
  }
}

.header-boldtext {
  font-family: "Quantico";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  margin-bottom: 10px;
  @media (min-width: 1025px) {
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 22px;
  }
}

.header-text {
  font-family: "Quantico";
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  margin-bottom: 10px;
  @media (min-width: 1025px) {
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 22px;
  }
}

.card-landing-buttons-center {
  position: absolute;
  width: 300px;
  height: 75px;
  left: 50%;
  margin-left: -150px;
  @media (min-width: 550px) and (max-width: 1024px) {
    width: 500px;
    left: 50%;
    margin-left: -248.5px;
  }
  @media (min-width: 1024px) {
    width: 600px;
    left: 50%;
    margin-left: -300px;
  }
}

.button-landing-act {
  background-color: #00ff66;
  width: 170px;
  height: 75px;
  margin-top: 10px;
  border: 1px solid black;
  box-sizing: border-box;
  @media (min-width: 550px) and (max-width: 1024px) {
    margin-right: 20px;
  }
  @media (min-width: 1024px) {
    width: 225px;
    margin-right: 20px;
  }
  outline: none;
}

.no-underline {
  text-decoration: none;
}

.no-underline:hover {
  text-decoration: none;
}

.button-landing-join {
  border: 1px solid #000000;
  background-color: #ffffff;
  width: 170px;
  height: 75px;
  margin-top: 10px;
  border: 1px solid black;
  @media (min-width: 1024px) {
    width: 225px;
  }
  outline: none;
}

.button-landing-act:hover,
.button-landing-act:focus {
  background-color: #00e35b !important;
}

.button-landing-join:hover,
.button-landing-join:focus {
  background-color: #f9f9f9 !important;
}

.button-landing-header {
  font-family: "Roboto Condensed";
  color: #000000;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 2.4px;
}

.button-landing-text {
  font-family: "Quantico";
  color: #000000;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
}

//act now css
.container-actnow {
  text-align: center;
  width: 100%;
  background-color: #f2f2f2;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 20px;
}

.empty-div {
  height: 15px;
  background: transparent;
}

.classaction-card {
  width: 90%;
  height: 452px;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 32px;
  @media (max-width: 1023px) {
    display: inline-block;
    font-size: 30px;
    height: 533px;
    width: 343px;
    margin-left: 0px;
    margin-right: 0px;
    top: 64px;
    line-height: 120%;
    // margin-bottom: 48px;
  }
}

.card-actnow {
  display: inline-block;
  // margin-top: 97px;
  width: 100%;
  margin-left: 40px;
  // @media (min-width: 1025px) {
  //   width: 815px;
  // }
  @media (max-width: 1023px) {
    font-size: 30px;
    height: 108px;
    width: 343px;
    margin-left: 16px;
    margin-top: 64px;
    line-height: 120%;
  }
}

.card-actnow-yahoo:hover,
.card-actnow-join-info:hover,
.card-actnow-join-arrow:hover {
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
  -webkit-transition: transform 0.1s ease 0s;
  transition: transform 0.1s ease 0s;
}

.header-actnow {
  font-family: "HelveticaNeueLtExt";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;

  /* identical to box height, or 29px */
  display: flex;
  align-items: flex-end;

  color: #000000;
  margin-bottom: 24px;
  margin-top: 32px;
  @media (max-width: 1023px) {
    font-size: 24px;
    height: 29px;
    width: 343px;
    // margin-left: 16px;
    // margin-top: 64px;
    line-height: 120%;
    margin-bottom: 16px;
    margin-top: 8px;
  }
}

.info-container {
  width: 50% !important;
  height: 100%;
  background-color: #f9f9f9;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  @media (max-width: 1023px) {
    width: 343px !important;
    height: 360px;
    display: inline-block;
    border-top-right-radius: 1px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    margin-top: -6px;
  }
}

.card-info {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  height: 370px;
  margin-top: 82px;
  @media (max-width: 1023px) {
    height: 305px;
    margin-left: 14.5px;
    margin-right: 14.5px;
    width: 304px;
    margin-top: 25px;
  }
}

.card-info-time {
  // position: absolute;
  width: 100%;
  height: 20px;
  margin-left: 0px;
  font-family: "Clarkson";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  text-align: left;
  /* identical to box height, or 20px */

  /* Grey 800 */
  color: #333333;
  @media (max-width: 1023px) {
    font-size: 14px;
  }
}

.card-info-header {
  width: 100%;
  height: 36px;
  margin-top: 8px;
  // position: absolute;
  text-align: left;
  margin-top: 5px;
  margin-left: 0px;
  font-family: "HelveticaNeueLtExt";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 120%;

  /* identical to box height, or 36px */

  /* HF Blue */
  color: #212121;
  @media (max-width: 1023px) {
    margin-top: 8px;
    font-size: 24px;
  }
}

.card-info-subheader {
  width: 100%;
  height: 44px;
  margin-top: 8px;
  text-align: left;
  // position: absolute;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;

  /* or 22px */

  /* Grey 800 */
  color: #333333;
  @media (max-width: 1023px) {
    margin-top: 8px;
    font-size: 16px;
  }
}

.card-info-payout {
  width: 100%;
  height: 20px;
  text-align: left;
  margin-top: 38px;
  // position: absolute;
  font-family: "Clarkson";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  text-align: left;
  /* identical to box height, or 20px */

  /* Grey 800 */
  color: #333333;
  @media (max-width: 1023px) {
    margin-top: 32px;
    font-size: 14px;
  }
}

.card-info-text {
  width: 100%;
  height: 23px;
  margin-top: 8px;
  text-align: left;
  // position: absolute;
  font-family: "Clarkson";
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 140%;
  text-align: left;
  /* identical to box height, or 20px */

  /* Grey 800 */
  color: #333333;
  @media (max-width: 1023px) {
    margin-top: 8px;
    font-size: 18px;
  }
}

.card-info-button {
  width: 100%;
  text-align: left;
  height: 45px;
  margin-top: 40px;
}

.claim-button {
  // position: absolute;
  // text-align: left !important;
  // margin-top: 40px;
  // margin-left: 0px;
  width: auto;
  height: auto;
  background: #60d78a;
  border-radius: 1px;
  border-color: #60d78a;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 140%;
  color: #121519;
  padding: 12px 40px !important;
  // padding-left: 40px !important;
  // padding-left: 10px !important;
  // padding-right: 10px;
}

.claim-button:hover {
  background: #55bc7a;
  color: #121519;
  /* Elevation 3 */
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1), 0px 7px 6px rgba(0, 0, 0, 0.04);
  border-radius: 1px;
  border-color: #55bc7a;
}

.claim-button:focus {
  background: #55bc7a;
  color: #121519;
  /* Elevation 3 */
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1), 0px 7px 6px rgba(0, 0, 0, 0.04);
  border-radius: 1px;
  border-color: #55bc7a;
}

.claim-button:active {
  background: #55bc7a;
  color: #121519;
  /* Elevation 3 */
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1), 0px 7px 6px rgba(0, 0, 0, 0.04);
  border-radius: 1px;
  border-color: #55bc7a;
}

.claim-button:disabled {
  background: #e5e5e5;
  color: #8a8e95;
  border-radius: 1px;
  border-color: #e5e5e5;
}

.claim-button[disabled]:hover {
  background: #e5e5e5;
  color: #8a8e95;
  border-radius: 1px;
  border-color: #e5e5e5;
}

.container-classaction-main {
  background-color: #ffffff !important;
  color: black;
  width: 100%;
  max-width: 100%;
  // margin: auto;
  // padding-left: 16px;
  // padding-right: 16px;
}

.container-classactionjoin-background {
  width: 100vw;
  height: 25vh;
  min-height: 321px;
  background: #60d78a;
  @media (max-width: 1023px) {
    margin-top: 84px;
    height: 384px;
    display: inline-block;
  }
}

.container-classactionjoin {
  width: 100%;
  max-width: 1500px;
  height: 100%;
  margin: 0 auto;
}

.card-classactionjoin {
  // position: relative;
  width: 100%;
  height: 72px;
  text-align: center !important;
  margin-top: 83px;
  display: inline-block;
  @media (max-width: 1023px) {
    height: 144px;
    width: 329px;
    font-size: 24px;
  }
}

.classactionjoin-header {
  // position: absolute;
  width: 500px;
  // width:100%;
  height: 72px;
  font-family: "HelveticaNeueLtExt";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 120%;
  /* or 36px */
  text-align: center !important;
  /* White */
  color: #ffffff;
  // margin-top: 83px;
  margin: 0 auto;
  @media (max-width: 1023px) {
    width: 329px;
    margin-left: 24px;
    height: 144px;
  }
}

.classactionjoin-button {
  margin-top: 32px;
  width: 100%;
  height: 45px;
  text-align: center;
  position: relative;
}

.button-classactionjoin {
  // position: relative;
  width: auto;
  height: auto;
  background: #ffffff;
  border-radius: 1px;
  border-color: #ffffff;
  padding: 12px 40px !important;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 140%;

  /* identical to box height, or 21px */
  // display: flex;
  // align-items: flex-end;
  text-align: center;
  text-transform: uppercase;

  /* Grey 900 */
  color: #121519;
  margin: 0 auto;
}

.button-classactionjoin:hover {
  background: #E5E5E5;
  border-radius: 1px;
  color: #121519;
  /* Elevation 3 */
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1), 0px 7px 6px rgba(0, 0, 0, 0.04);
  border-radius: 1px;
  border-color: #E5E5E5;
}

.actnow-content-header {
  margin-left: 55px;
  margin-top: 70px;
  font-family: "HelveticaNeueLtExt";
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 105%;

  /* or 44px */

  /* Black */
  color: #000000;
  width: 436px;
  height: 209px;
  text-align: left;
  @media (max-width: 1023px) {
    font-size: 30px;
    height: 108px;
    width: 343px;
    margin-left: 16px;
    margin-top: 64px;
    line-height: 120%;
  }
}

.actnow-content-text {
  margin: 0 auto;
  margin-top: 70px;
  font-family: "ITCBerkeleyStandard";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 120%;

  /* or 44px */

  /* Black */
  color: #000000;
  width: 663px;
  height: 270px;
  text-align: left;
  @media (max-width: 1023px) {
    font-size: 24px;
    height: auto;
    width: 343px;
    margin-left: 16px;
    margin-top: 24px;
    line-height: 120%;
  }
}
.actnow-content {
  @media (max-width: 1023px) {
    width: 100%;
  }
}

.fb-image {
  background-image: url("../../../assets/images/facebook.svg");
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
}

.instagram-image {
  background-image: url("../../../assets/images/instagram.svg");
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
}

.twitter-image {
  background-image: url("../../../assets/images/twitter.svg");
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
}
