.signin-form {
  min-width: 300px;
  max-width: 800px;
  height: 100%;
  margin: auto;
}

.container-signin-bg {
  position: relative;
  background: linear-gradient(90deg, #1a1c25 50%, #ffffff 50%);
  color: black;
  width: 100vw;
  max-width: 100%;
  height: 1000px;
  min-height: 650px;
  @media (max-width: 1023px) {
    height: 750px;
    // display: inline-block;
    background: linear-gradient(#1a1c25 50%, #ffffff 50%);
  }
}

.signin-container-content {
  width: 42%;
  height: 100%;
  background-color: #1a1c25;
  @media (max-width: 1023px) {
    width: 100%;
    height: 50%;
    display: inline-block;
    background-color: transparent;
    // background: linear-gradient(#1a1c25 50%, #ffffff 50%);
  }
}
.container-signin {
  width: 100%;
  height: 100%;
  max-width: 1500px;
  margin: 0 auto;
  margin-top: 28px;
  @media (max-width: 1023px) {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
}

.container-signin-form {
  width: 100%;
  margin: auto;
  align-items: center;
  margin-top: 150px;
  height: 700px;
  @media (max-width: 1023px) {
    height: 207px;
    width: 100%;
    display: inline-block;
    margin-top: 30px;
    // background: linear-gradient(#1a1c25 50%, #ffffff 50%);
  }
}

.signin-form-container {
  width: 58%;
  height: 100%;
  background-color: #ffffff;
  @media (max-width: 1023px) {
    height: 50%;
    width: 100%;
  }
}

.form-group-signin {
  margin: auto;
  padding: 5px;
}

.col-text-maxwidth {
  max-width: 310px;
  width: 100%
}

.signin-button {
  background-color: #60d78a!important;
  font-family: "HelveticaNeue" !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  letter-spacing: 1.6px !important;
  color: #121212 !important;
  border: 0 !important;
  border-radius: 0 !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06) !important;
  width: 100%;
  height: 54px;
  padding: 0 !important;
  margin-top: 10px !important;
  margin-bottom: 10px;
  @media screen and (max-width: 420px) {
    height: 44px;
    font-size: 14px !important;
    font-weight: bold !important;
    letter-spacing: 1.4px !important;
  }
  // @media (max-width: 1023px) {
  //   width:75%
  // }
}

.signin-button:hover {
  background-color: #55bc7a !important;
}

.user-details-table {
  background-color: #f9f9f9;
  border: none !important;
  appearance: none !important;
  width: 90vw;
  padding: 0;
  margin: auto;
}

.signin-header {
  margin-top: 258px;
  margin-left:40px;
  position: relative;
  font-family: "HelveticaNeueLtExt";
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 105%;

  /* identical to box height, or 44px */

  color: #ffffff;
  padding-bottom: 20px;
  @media (max-width: 1023px) {
    font-size: 24px;
    margin-top: 138px;
    margin-left: 24px;
  }
}

.signin-header::after {
  position: absolute;
  content: "";
  width: 57px;
  height: 2px;
  left: 0;
  bottom: 0;
  background-color: #ffffff;
}
.signin-text {
  width: 403px;
  margin-left:40px;
  margin-top: 30px;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;

  /* or 22px */

  /* Grey 200 */
  color: #f9f9f9;
  @media (max-width: 1023px) {
    width: 327px;
    font-size: 16px;
    // margin-top: 138px;
    margin-left: 24px;
  }
}

.verify-error-text {
	font-family: 'Roboto Condensed';
	font-size: 12px;
	font-stretch: condensed;
	color: red;
	margin-top: 0;
	text-align: center;
	width: 100%;
	margin-top: 5px;
}
