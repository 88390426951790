.navbar-container {
  width: 100vw;
  max-width: 1500px;
  text-align: center;
  background-color: transparent !important;
  z-index: -10;
  margin-bottom: -104px;
}

.navbar {
  z-index: 1;
  background-color: transparent;
  // background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
}

.navbar-inner {
  background: transparent;
}

.nav-brand-header {
  // background-image: url("../../../assets/images/ddp-logo-with-name.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  height: 90px;
  width: 90px;
}

.a-header {
  // color: black;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  outline: none !important;
  box-shadow: none !important;
}

.a-header:hover {
  font-weight: bold;
  color: #00ff66;
  /* box-shadow: none !important; */
}

.a-header:focus {
  outline: none !important;
  box-shadow: none !important;
}

.a-header:active {
  outline: none !important;
  box-shadow: none !important;
}

.a-header.active:focus {
  outline: none !important;
  box-shadow: none !important;
}

.button {
  outline: none !important;
  // border-radius: 0px;
}

.container-account-nav {
  font-family: "Roboto Condensed";
  background-color: #f9f9f9;
  color: royalblue;
  font-size: 12px;
  letter-spacing: 0.86px;
  font-weight: bold;
  font-stretch: condensed;
}

.a-header-nav {
  color: royalblue;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  outline: none !important;
  box-shadow: none !important;
}

.a-header-nav:hover {
  font-weight: bold;
  color: #e5e5e5;
  // color: black;
  /* box-shadow: none !important; */
}

.a-header-nav:focus {
  outline: none !important;
  box-shadow: none !important;
}

.a-header-nav:active {
  outline: none !important;
  box-shadow: none !important;
}

.a-header-nav.active:focus {
  outline: none !important;
  box-shadow: none !important;
}

.sticky-nav {
  position: sticky;
  top: 0;
}

.header-item {
  // color: #ffffff !important;
  font-family: "HelveticaNeue";
  font-stretch: condensed;
  font-style: "";
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  text-transform: uppercase;
  margin-right: 24px;
  display: flex;
  align-items: flex-end;
  text-transform: uppercase;

  // color: #ffffff;

  /* Inside Auto Layout */
}

.header-item:hover { 
  color: #e5e5e5;
}
.header-button {
  position: relative;
  top: 18.5%;
  width: 117px;
  height: 45px;
  background-color: #60d78a !important;
  font-family: "HelveticaNeue";
  font-size: 15px;
  font-style: normal;
  font-weight: bold;
  line-height: 140%;
  letter-spacing: 0em;
  text-align: center;
  padding: 12px 0px;
  // outline:none !important;
  color: #121519;
  border-radius: 1px;
  border-color: #60d78a;
}

.header-button:hover {
  background: #55bc7a !important;
  /* Elevation 3 */
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1), 0px 7px 6px rgba(0, 0, 0, 0.04);
  border-radius: 1px;
  border-color: #55bc7a;
  color: #121519;
}

.header-desktop {
  @media (max-width: 1023px) {
    display: none;
  }
}

.header-mobile {
  @media (min-width: 1024px) {
    display: none;
  }
  background-color: black !important;
  height: 70px;
  // position: fixed;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 26px;
  height: 20px;
  left: calc(100vw - 35px);
  top: 2%;
  z-index: 100;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: black;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: white;
  padding: 0.8em;
  text-align: left;
}

/* Individual item */
.bm-item {
  display: inline-block;
  color: white;
  font-family: "HelveticaCondensed";
  font-size: 25px;
  font-style: normal;
  font-weight: bold;
  line-height: 140%;
  margin-top: 20px;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.nav-brand-header-mobile {
  // background-image: url("/images/ddp-logo.svg");
  height: 60px;
  width: 60px;
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 100;
}
