.row {
	@media (max-width: 1023px) {
		display: inline-block !important;
	}
}
.container-aboutus-main {
	background-color: #ffffff !important;
	color: black;
	width: 100%;
	max-width: 100%;
	// margin: auto;
	// padding-left: 16px;
	// padding-right: 16px;
}

.container-aboutus-background {
	width: 100vw;
	height: 70vh;
	background-image: url("../../assets/images/aboutus.jpg") !important;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	max-width: 100vw !important;
	@media (max-width: 1023px) {
		height: 375px;
		background-size: cover;
	}
}
.container-aboutus {
	position: relative;
	width: 100%;
	max-width: 1500px;
	height: 100%;
	margin: 0 auto;
}

.header-aboutus {
	position: absolute;
	width: 458px;
	height: 138px;
	font-family: "HelveticaNeueLtExt";
	font-style: normal;
	font-weight: 500;
	font-size: 44px;
	line-height: 105%;

	/* or 46px */
	display: flex;
	align-items: flex-end;

	/* White */
	color: #000000;
	left: 40px;
	bottom: 30px;
	@media (max-width: 1023px) {
		font-size: 42px;
		width: 327px;
		height: 44px;
		bottom: 25px;
		left: 16px;
	}
}

.container-aboutustext-background {
	width: 100vw;
	// height: 1996px;
	background-color: #ffffff;
}

.container-aboutustext {
	position: relative;
	width: 100%;
	max-width: 1500px;
	height: 100%;
	margin: 0 auto;
	padding-bottom:96px;
}

.aboutus-whatis-header {
	width: 42%;
	height: 300px;
	margin-left: 40px;
	font-family: "HelveticaNeueLtExt";
	font-style: normal;
	font-weight: 500;
	font-size: 42px;
	line-height: 105%;
	color: #000000;
	@media (max-width: 1023px) {
		width: 343px;
		font-size: 30px;
		margin-left: 16px;
		padding: 0;
		height: 72px;
	}
}

.aboutus-whatis-text {
	width: 54%;
	height: 300px;
	// padding-left: 8%;
	font-family: "ITCBerkeleyStandard";
	font-style: normal;
	font-weight: 500;
	font-size: 26px;
	line-height: 120%;

	/* or 30px */

	/* Grey 900 */
	color: #121519;
	@media (max-width: 1023px) {
		display: inline-block;
		width: 343px;
		font-size: 25px;
		margin-left: 16px;
	}
}

.aboutus-middle {
	height: 390px;
	width: 100%;
	text-align: center;
	margin-top: 96px;
	@media (max-width: 1023px) {
		width: 343px;
		// font-size: 30px;
		height: 613px;
		// margin-left: 16px;
		margin-top: 60px;
		display: inline-block;
	}
}

.aboutus-middle-header {
	width: 40%;
	height: 36px;
	font-family: "HelveticaNeueLtExt";
	font-style: normal;
	font-weight: 500;
	font-size: 36px;
	line-height: 120%;
	color: #000000;
	margin: 0 auto;
	text-align: left;
	@media (max-width: 1023px) {
		width: 343px;
		font-size: 30px;
		height: 108px;
		margin-left: 16px;
		// margin-top:60px;
		display: inline-block;
	}
}

.aboutus-middle-text {
	width: 40%;
	text-align: left;
	margin: 0 auto;
	font-family: "HelveticaNeue";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 120%;
	/* or 22px */

	/* Grey 800 */
	color: #333333;
	margin-top: 20px;
	@media (max-width: 1023px) {
		margin-top: 0;
		width: 343px;
		font-size: 18px;
		height: 508px;
		margin-left: 16px;
		// margin-top:60px;
		display: inline-block;
	}
}

.video-div {
	margin: 0 auto;
	height: 534px;
	width: 78%;
	max-width: 951px;
	margin-top: 96px;
	@media (max-width: 1023px) {
		width: 343px;
		// font-size: 30px;
		height: 198px;
		margin-left: 16px;
		margin-top: 60px;
		display: inline-block;
	}
}

.video-iframe {
	width: 100%;
	height: 100%;
}

.container-aboutustextgrey-background {
	max-height: 800px;
	min-height: 650px;
	background-color: #f5f5f5;
	@media (max-width: 1023px) {
		width: 100%;
		margin-bottom: 15px;
		max-height: 1700px;
		// margin-left: 16px;
		// margin-top:60px;
		display: inline-block;
	}
	z-index: -1;
}

.grey-middle {
	margin-top: 80px;
}

.container-faq {
	font-family: "Roboto Condensed";
	background-color: #ffffff !important;
	color: black;
	width: 100% !important;
	min-height: 550px;
	padding: 0 !important;
	margin: 0 !important;
}

h3 {
	min-height: 35px;
	font-family: "Clarkson" !important;
	font-size: 18px;
	font-weight: 500;
	line-height: 130%;
	color: #000000;
	text-align: left;
	width: 100%;
	padding-left: none !important;
	margin-bottom: 12px !important;
}
p {
	font-family: "HelveticaNeue" !important;
	font-size: 14px;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 140%;
	color: #121212;
	text-align: left;
	width: 100%;
	padding: 0;
	margin-bottom: 16px;
}

.howitworks-markdown-container {
	h2 {
		position: relative !important;
		height: 96px;
		font-family: "Clarkson";
		font-size: 18px;
		font-weight: bold;
		line-height: 0.96;
		letter-spacing: normal;
		color: #121212;
		width: 95%;
		margin-top: 12px !important;
		margin-bottom: 20px !important;
	}
	h3 {
		min-height: 35px;
		font-family: "Clarkson" !important;
		font-size: 18px;
		font-weight: bold;
		line-height: 1.03;
		color: #000000;
		text-align: left;
		width: 100%;
		padding-left: none !important;
		margin-bottom: 12px !important;
	}
	p {
		font-family: "HelveticaNeue	";
		font-size: 14px;
		font-stretch: condensed;
		color: #121212;
		text-align: left;
		width: 100%;
		padding: 0;
		margin-bottom: 16px;
		@media screen and (min-width: 1025px) {
			font-size: 16px;
			margin-bottom: 22px;
		}
	}
	li {
		font-family: "Roboto Condensed" !important;
		font-size: 14px;
		color: #121212;
		padding-bottom: 12px;
		@media screen and (min-width: 1025px) {
			font-size: 16px;
		}
	}
	ol {
		padding: 0 13px !important;
		font-family: "Roboto Condensed";
		font-size: 14px;
		font-stretch: condensed;
	}
}

.faq-heading {
	position: relative !important;
	min-height: 96px;
	font-family: "HelveticaNeueLtExt";
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 120%;
	width: 95%;
	padding-top: 12px !important;
	padding-left: 35px !important;
	padding-right: 12px !important;
}

.faq-title {
	font-size: 24px;
	position: relative !important;
	font-family: Oswald;
	font-weight: bold;
	line-height: 0.96;
	letter-spacing: normal;
	color: #121212;
	width: 95%;
	padding: 12px 35px !important;
}

.faq-text {
	font-family: Roboto Condensed;
	font-size: 14px;
	font-stretch: condensed;
	color: #121212;
	text-align: left;
	width: 100%;
	padding: 0;
	padding-left: 35px !important;
	padding-right: 12px !important;
}

.card-container {
	position: relative !important;
	border: none !important;
	box-shadow: 0px 1px 0px #e2e2e2;
	border-radius: 1px;

	background-color: #ffffff !important;
	padding-left: 0px !important;
	// padding-top:24px;
	// padding-bottom:25px;
	// padding-right: 12px !important;
	width: 100%;
	// height:72px;
}

.card-text {
	font-family: "Clarkson";
	font-size: 14px;
	font-stretch: condensed;
	color: #121212;
	text-align: left;
	width: 100%;
	padding: 0.5rem 23px;
	@media (min-width: 1025px) {
		font-size: 16px;
	}
}

.card-title {
	min-height: 35px;
	font-family: "Clarkson";
	// font-weight: bold;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 130%;

	/* or 23px */
	display: flex;
	align-items: center;

	/* Grey 900 */
	color: #121519;
	text-align: left;
	width: 100%;
	cursor: pointer;
	padding-left: none !important;
}

.card-header {
	border: none !important;
	background-color: #ffffff !important;
	// font-family: 'Clarkson';
	// background-color: white !important;
	padding-left: 0px;
	padding-top: 6px;
	padding-bottom: 12px;
}

.aboutus-link {
		font-style: bold;
		text-decoration: underline;
}

.aboutus-link:hover {
  color: #55bc7a !important;
}

// .card-header ::after {
// 	width: 24px;
// 	height: 24px;
// 	content: "";
// 	float: right;
// 	background-image: url("../../assets/images/Plus.svg") !important;
// }

// .card-header.collapsed ::after {
// 	width: 24px;
// 	height: 24px;
// 	content: "";
// 	float: right;
// 	background-image: url("../../assets/images/Minus.svg") !important;
// }
