.email-col {
  border: none !important;
  appearance: none !important;
  text-align: left;
  padding: 0.25rem !important;
}

.email-col-1 {
  padding-left: 0px !important;
  padding-top: 0px !important;
  margin-top: 0px !important;
	max-width: 33%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  border: 1px solid #f9f9f9 !important;
	@media screen and (max-width: 768px) {
		max-width: 40%;
	}
}

.email-col-2 {
  padding-left: 0px !important;
  padding-top: 0px !important;
  margin-top: 0px !important;
	max-width: 33%;
  border: 1px solid #f9f9f9 !important;
	@media screen and (max-width: 768px) {
		max-width: 10%;
	}
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.email-col-text-bold {
  font-family: Quantico;
  font-size: 16px;
  font-weight: bold;
  color: #121212;
  line-height: 1.13;
}

.email-col-text {
  font-family: Roboto Condensed;
  font-size: 14px;
  font-stretch: condensed;
  color: #121212;
  text-align: left;
}

.email-col-text-div {
  max-width: 350px !important;
  font-family: Roboto Condensed;
  font-size: 14px;
  font-stretch: condensed;
  color: #121212;
  text-align: left;
  font-weight: bold;
}

.email-input {
  color: #121212;
  -webkit-appearance: none !important;
  -moz-appearance: textfield !important;
  appearance: none !important;
  outline: none !important;
  display: inline;
  padding-left: 0px !important;
  border-color: #f9f9f9 !important;
  width: 100%;
  max-width: 350px;
}

.email-input::placeholder {
  text-transform: capitalize !important;
}

.uppercase-text {
  text-transform: uppercase;
}

.email-input:focus {
  color: #121212;
  border-color: #f9f9f9 !important;
  box-shadow: none !important;
}

.email-table {
  background-color: #f9f9f9;
  border: none !important;
  appearance: none !important;
  width: 95%;
  padding: 0;
  max-width: 1000px;
  margin-left: 15px !important;
}

.email-button {
  background-color: #00ff66 !important;
  font-family: 'Roboto Condensed' !important;
  font-size: 16px !important;
  font-weight: bold !important;
  font-stretch: condensed !important;
  letter-spacing: 1.6px !important;
  color: #121212 !important;
  outline: none !important;
  border: none !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06) !important;
}

.email-button:hover {
  background-color: #00E35B !important;
}

.container-email-bg {
  font-family: "Roboto Condensed";
  background-color: #F9F9F9;
  color: black;
  width: 100vw;
  height: 100vh;
  min-height: 750px;
}

.container-email {
  width:100%;
  max-width: 1500px;
  height: 100%;
  margin: 0 auto;
}

.email-heading {
  font-family: 'Quantico';
  font-size: 32px ;
  color: black;
  font-weight: bold;
  margin-left: 15px;
}

.form-check-blue-label {
  font-family: 'Roboto Condensed';
  font-size: 13px !important;
  line-height: 16px;
  color: royalblue !important;
  font-stretch: condensed;
  padding-left: 2px;
}

.email-form-container {
  width:100vw;
  max-width: 1500px;
  overflow:auto;
  height:400px;
  @media (max-width: 600px) {
    margin-top:50px;
  }
}